import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import { maintenancesReducer, maintenanceReducer } from './maintenance/reducers';
import chartContentReducer from './chartContent/reducers';
import ChangeLayoutMode from './themeLayout/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  maintenances: maintenancesReducer,
  maintenance: maintenanceReducer,
  chartContent: chartContentReducer,
  ChangeLayoutMode,
});

export default rootReducers;
