const PERMISSION = {
  dashboard: {
    general: '',
    maintenance: 'dashboard maintenance-view',
  },
  master: {
    modify: 'master modify',
    view: 'master view',
  },
  vehicleLicense: {
    modify: 'vehicle-license modify',
    view: 'vehicle-license view',
  },
  kimper: {
    modify: 'kimper modify',
    view: 'kimper view',
  },
  maintenance: {
    modify: 'maintenance modify',
    view: 'maintenance view',
  },
  operation: {
    modify: 'operation modify',
    view: 'operation view',
  },
  partRequisition: {
    modify: 'part-requisition modify',
    view: 'part-requisition view',
    partRequisitionAcknowledge: 'part-requisition part-requisition-acknowledge',
    partRequisitionApprove: 'part-requisition part-requisition-approve',
    partRequisitionReject: 'part-requisition part-requisition-reject',
    purchaseOrderCancel: 'part-requisition purchase-order-cancel',
    purchaseOrderClose: 'part-requisition purchase-order-close',
  },
  warehouseInventory: {
    modify: 'warehouse-inventory modify',
    goodReceiptPoReceipt: 'warehouse-inventory good-receipt-po-receipt',
    goodReceiptWoReceipt: 'warehouse-inventory good-receipt-wo-receipt',
    view: 'warehouse-inventory view',
  },
  users: {
    modify: 'users modify',
    view: 'users view',
  },
};

export { PERMISSION };
