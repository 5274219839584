import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const DailyLog = lazy(() => import('../../container/operation/daily-log'));
const TripIndex = lazy(() => import('../../container/operation/trip'));
const TripCreate = lazy(() => import('../../container/operation/trip/Create'));
const TripEdit = lazy(() => import('../../container/operation/trip/Edit'));
const TripShow = lazy(() => import('../../container/operation/trip/Show'));

function OperationRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/daily-log`} component={DailyLog} />
      <Route exact path={`${path}/trip`} component={TripIndex} />
      {permissions.includes(PERMISSION.operation.modify) && <Route exact path={`${path}/trip/create`} component={TripCreate} />}
      {permissions.includes(PERMISSION.operation.modify) && <Route exact path={`${path}/trip/:tripId/edit`} component={TripEdit} />}
      <Route exact path={`${path}/trip/:tripId`} component={TripShow} />
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/daily-log`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default OperationRoutes;
