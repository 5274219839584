import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Vehicle = lazy(() => import('../../container/maintenance/vehicle'));
const VehicleShow = lazy(() => import('../../container/maintenance/vehicle/Show'));

const List = lazy(() => import('../../container/maintenance/list'));

const WorkOrderIndex = lazy(() => import('../../container/maintenance/work-order'));
const WorkOrderCreate = lazy(() => import('../../container/maintenance/work-order/Create'));
const ExternalWorkOrderCreate = lazy(() => import('../../container/maintenance/work-order/CreateExternal'));
const WorkOrderShow = lazy(() => import('../../container/maintenance/work-order/Show'));
const WorkOrderEdit = lazy(() => import('../../container/maintenance/work-order/Edit'));
const WorkOrderDone = lazy(() => import('../../container/maintenance/work-order/Done'));

function MaintenanceRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/vehicle`} component={Vehicle} />
      <Route path={`${path}/vehicle/:vehicleId`} component={VehicleShow} />
      <Route path={`${path}/list`} component={List} />
      <Route exact path={`${path}/work-order`} component={WorkOrderIndex} />
      {permissions.includes(PERMISSION.maintenance.modify) && <Route exact path={`${path}/work-order/create`} component={WorkOrderCreate} />}
      {permissions.includes(PERMISSION.maintenance.modify) && <Route exact path={`${path}/work-order/external/create`} component={ExternalWorkOrderCreate} />}
      <Route exact path={`${path}/work-order/:workOrderId`} component={WorkOrderShow} />
      {permissions.includes(PERMISSION.maintenance.modify) && <Route exact path={`${path}/work-order/:workOrderId/edit`} component={WorkOrderEdit} />}
      {permissions.includes(PERMISSION.maintenance.modify) && <Route exact path={`${path}/work-order/:workOrderId/done`} component={WorkOrderDone} />}
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/vehicle`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default MaintenanceRoutes;
