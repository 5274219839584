const actions = {
  MAINTENANCES_BEGIN: 'MAINTENANCES_BEGIN',
  MAINTENANCES_SUCCESS: 'MAINTENANCES_SUCCESS',
  MAINTENANCES_ERR: 'MAINTENANCES_ERR',

  MAINTENANCE_REQUEST: 'MAINTENANCE_REQUEST',
  MAINTENANCE_REQUEST_ERR: 'MAINTENANCE_REQUEST_ERR',

  SINGLE_MAINTENANCE_BEGIN: 'SINGLE_MAINTENANCE_BEGIN',
  SINGLE_MAINTENANCE_SUCCESS: 'SINGLE_MAINTENANCE_SUCCESS',
  SINGLE_MAINTENANCE_ERR: 'SINGLE_MAINTENANCE_ERR',

  SINGLE_MAINTENANCE_UPDATE: 'SINGLE_MAINTENANCE_UPDATE',
  MAINTENANCE_MEMBER_UPDATE: 'MAINTENANCE_MEMBER_UPDATE',
  COMPLETE_MAINTENANCE_SUCCESS: 'COMPLETE_MAINTENANCE_SUCCESS',

  maintenancesBegin: () => {
    return {
      type: actions.MAINTENANCES_BEGIN,
    };
  },

  maintenancesSuccess: (data) => {
    return {
      type: actions.MAINTENANCES_SUCCESS,
      data,
    };
  },

  maintenancesErr: (err) => {
    return {
      type: actions.MAINTENANCES_ERR,
      err,
    };
  },

  maintenanceRequest: () => {
    return {
      type: actions.MAINTENANCE_REQUEST,
    };
  },

  maintenanceRequestErr: () => {
    return {
      type: actions.MAINTENANCE_REQUEST_ERR,
    };
  },

  singleMaintenanceBegin: () => {
    return {
      type: actions.SINGLE_MAINTENANCE_BEGIN,
    };
  },

  singleMaintenanceSuccess: (data) => {
    return {
      type: actions.SINGLE_MAINTENANCE_SUCCESS,
      data,
    };
  },

  singleMaintenanceErr: (err) => {
    return {
      type: actions.SINGLE_MAINTENANCE_ERR,
      err,
    };
  },

  singleMaintenanceUpdate: (data) => {
    return {
      type: actions.SINGLE_MAINTENANCE_UPDATE,
      data,
    };
  },

  maintenanceMemberUpdate: (data) => {
    return {
      type: actions.MAINTENANCE_MEMBER_UPDATE,
      data,
    };
  },

  completeMaintenanceSuccess: () => {
    return {
      type: actions.COMPLETE_MAINTENANCE_SUCCESS,
    };
  },
};

export default actions;
