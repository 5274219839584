import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import Dashboard from './dashboard';
import Maintenance from './maintenance';
import Master from './master';
import Operation from './operation';
import PartRequisition from './part-requisition';
import User from './user';
import WarehouseInventory from './warehouse-inventory';
import withAdminLayout from '../../layout/withAdminLayout';
import { PERMISSION } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Kimper = lazy(() => import('../../container/kimper'));
const Profile = lazy(() => import('../../container/user/Profile'));
const VehicleLicense = lazy(() => import('../../container/vehicle-license'));

const Home = () => {
  const { path } = useRouteMatch();
  const permissions = useSelector((state) => state.auth.user.permissions);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}dashboard`} component={Dashboard} />
        {permissions.includes(PERMISSION.kimper.view) && <Route exact path={`${path}kimper`} component={Kimper} />}
        {permissions.includes(PERMISSION.maintenance.view) && <Route path={`${path}maintenance`} component={Maintenance} />}
        {permissions.includes(PERMISSION.master.view) && <Route path={`${path}master`} component={Master} />}
        {permissions.includes(PERMISSION.operation.view) && <Route path={`${path}operation`} component={Operation} />}
        {permissions.includes(PERMISSION.partRequisition.view) && <Route path={`${path}part-requisition`} component={PartRequisition} />}
        {permissions.includes(PERMISSION.users.view) && <Route path={`${path}user`} component={User} />}
        <Route path={`${path}profile`} component={Profile} />
        {permissions.includes(PERMISSION.vehicleLicense.view) && <Route exact path={`${path}vehicle-license`} component={VehicleLicense} />}
        {permissions.includes(PERMISSION.warehouseInventory.view) && <Route path={`${path}warehouse-inventory`} component={WarehouseInventory} />}
        <Route exact path={['/sign-in', '/']}>
          <Redirect to={`${path}dashboard`} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Home);
