import actions from './actions';

const {
  MAINTENANCES_BEGIN,
  MAINTENANCES_SUCCESS,
  MAINTENANCES_ERR,

  MAINTENANCE_REQUEST,
  MAINTENANCE_REQUEST_ERR,

  SINGLE_MAINTENANCE_BEGIN,
  SINGLE_MAINTENANCE_SUCCESS,
  SINGLE_MAINTENANCE_ERR,

  SINGLE_MAINTENANCE_UPDATE,
  MAINTENANCE_MEMBER_UPDATE,
  COMPLETE_MAINTENANCE_SUCCESS,
} = actions;

const maintenancesInitialState = {
  data: {},
  loading: false,
  error: null,
};

const maintenancesReducer = (state = maintenancesInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAINTENANCES_BEGIN:
      return {
        ...maintenancesInitialState,
        loading: true,
      };
    case MAINTENANCES_SUCCESS:
      return {
        ...maintenancesInitialState,
        data,
        loading: false,
      };
    case MAINTENANCES_ERR:
      return {
        ...maintenancesInitialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const maintenanceInitialState = {
  data: {},
  loading: true,
  error: null,
};

const maintenanceReducer = (state = maintenanceInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAINTENANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MAINTENANCE_REQUEST_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    case SINGLE_MAINTENANCE_BEGIN:
      return {
        ...maintenanceInitialState,
        loading: true,
      };

    case SINGLE_MAINTENANCE_SUCCESS:
      return {
        ...maintenanceInitialState,
        data,
        loading: false,
      };
    case SINGLE_MAINTENANCE_ERR:
      return {
        ...maintenanceInitialState,
        error: err,
        loading: false,
      };
    case SINGLE_MAINTENANCE_UPDATE:
      return {
        ...state,
        data: { ...state.data, ...data },
        loading: false,
      };
    case MAINTENANCE_MEMBER_UPDATE:
      return {
        ...state,
        data: { ...state.data, members: data },
      };
    case COMPLETE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, status: 'selesai' },
      };
    default:
      return state;
  }
};

export { maintenanceReducer, maintenancesReducer };
