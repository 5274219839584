import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Index = lazy(() => import('../../container/user'));
const Create = lazy(() => import('../../container/user/Create'));
const Edit = lazy(() => import('../../container/user/Edit'));

function UserRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={Index} />
      {permissions.includes(PERMISSION.users.modify) && <Route exact path={`${path}/create`} component={Create} />}
      {permissions.includes(PERMISSION.users.modify) && <Route exact path={`${path}/:userId/edit`} component={Edit} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default UserRoutes;
